export const useMenuStore = defineStore('menu', () => {
  const { showAdminInterface } = storeToRefs(useAuthStore())
  const items: Ref<MenuItem[]> = computed(() => {
    return showAdminInterface.value
      ? [
          {
            icon: 'SvgoDashboard',
            link: '/admin',
            translate_key: 'dashboard',
          },
          {
            icon: 'SvgoEdit',
            link: '/admin/risk-assessment',
            translate_key: 'risk_assessment',
            subItems: [
              {
                translate_key: 'risk_value_chains',
                link: '/risk-value-chains',
              },
              {
                translate_key: 'areas',
                link: '/areas',
              },
              {
                translate_key: 'risks',
                link: '/risks',
              },
              {
                translate_key: 'scenarios',
                link: '/scenarios',
              },
              {
                translate_key: 'probability_impact_definitions',
                link: '/probability-impact-definitions',
              },
            ],
          },
          {
            icon: 'SvgoUsers',
            link: '/admin/users',
            translate_key: 'users',
          },
          {
            icon: 'SvgoBook',
            link: '/admin/treatment-catalog',
            translate_key: 'treatment_catalog',
          },
          {
            icon: 'SvgoMyProfile',
            link: '/profile',
            translate_key: 'my_profile',
          },
        ]
      : [
          {
            icon: 'SvgoProjects',
            link: '/projects',
            translate_key: 'projects',
          },
          {
            icon: 'SvgoBook',
            link: '/treatment-catalog',
            translate_key: 'treatment_catalog',
          },
          {
            icon: 'SvgoMyProfile',
            link: '/profile',
            translate_key: 'my_profile',
          },
        ]
  })

  const isMenuCollapsed = ref(true)
  const isChangeAreaMenuCollapsed = ref(true)
  const menuItemSelected = ref(items.value[0].translate_key)
  const subMenuItemSelected = ref<string>('')
  const addAreaPopup = ref(false)

  function toggleMenu() {
    isMenuCollapsed.value = !isMenuCollapsed.value
  }
  function toggleChangeAreaMenu() {
    isChangeAreaMenuCollapsed.value = !isChangeAreaMenuCollapsed.value
  }

  function collapseMenus() {
    isMenuCollapsed.value = true
    isChangeAreaMenuCollapsed.value = true
  }

  function selectMenuItem(index: number, subIndex = 0) {
    menuItemSelected!.value = items.value[index].translate_key
    if (items.value[index].subItems) {
      subMenuItemSelected.value
        = items.value[index].subItems[subIndex].translate_key
    }
  }

  function initializeSelectedMenuItem() {
    const route = useRoute()
    const currentRoute = route.path

    const findMenuItem = (items: MenuItem[], path: string): any => {
      for (let i = 0; i < items.length; i++) {
        const item = items[i]
        if (item.subItems) {
          for (let j = 0; j < item.subItems.length; j++) {
            if (item.link + item.subItems[j].link === path) {
              return { menuIndex: i, subIndex: j }
            }
          }
        }
        if (item.link === path) {
          return { menuIndex: i, subIndex: 0 }
        }
      }
      return { menuIndex: 0, subIndex: 0 }
    }

    const { menuIndex, subIndex } = findMenuItem(items.value, currentRoute)

    selectMenuItem(menuIndex, subIndex)
  }

  initializeSelectedMenuItem()

  return {
    items,
    toggleMenu,
    collapseMenus,
    toggleChangeAreaMenu,
    isChangeAreaMenuCollapsed,
    isMenuCollapsed,
    menuItemSelected,
    selectMenuItem,
    addAreaPopup,
    subMenuItemSelected,
  }
})
